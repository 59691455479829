
import moment from 'moment';
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';

import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';
import CrudEditModal from '@gid/vue-common/components/crud-list/CrudEditModal.vue';
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';
import Comment from '@gid/vue-common/components/Comment.vue';
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange.vue';
import RichTextComments from '@gid/vue-common/components/inputs/RichTextComments.vue';
import ProductsList from './ProductsList.vue';
import ProductOrderFilters from './ProductOrderFilters.vue';
import ProductOrdersModule from '../../store/product-orders.module';
import ProductOrdersPendingModule from '../../store/product-orders-pending.module';
import {
  CondOperator,
  CreateQueryParams,
  RequestQueryBuilder,
  SCondition,
} from '@dataui/crud-request';
import { getModule } from 'vuex-module-decorators';
import { TranslateResult } from 'vue-i18n';
import { mapGetters } from 'vuex';
import { BIconFileEarmarkSpreadsheet, BvModalEvent } from 'bootstrap-vue';
import { CommentActionTypeEnum } from '@gid/models/dist/entities/comment';
import axios from 'axios';
import { SupplierTypesEnum } from '@gid/models/dist';
import { checkProductsRequiringSerialNumber } from './ProductOrdersPending.vue';

interface JobWithSameProduct {
  id: string;
  name: string;
}

@Component({
  components: {
    ProductOrderFilters,
    CrudListTable,
    CrudListErrorMessage,
    CrudListPagination,
    CrudMultiSelect,
    CrudEditModal,
    Comment,
    DatePicker,
    ProductsList,
    DaysSinceLastChange,
    RichTextComments,
    BIconFileEarmarkSpreadsheet,
  },
  computed: {
    ...mapGetters({
      accessToken: 'access_token',
      locale: 'locale',
    }),
  },
})
export default class ProductOrdersOrdered extends Vue {
  @Ref('table') readonly table;
  @Ref('modal') readonly modal;

  accessToken!: String;
  locale!: string;

  order: any = {
    carrier: null,
    shipTo: null,
    trackingID: null,
    dateExpected: null,
    eid: null,
    items: [],
    isOrderedByBrand: false,
    opportunityId: null,
    loading: false,
    error: null,
  };
  originalProductItems: any[] = [];
  newComment = {
    message: null,
    visible_for_roles: [],
    action_type: CommentActionTypeEnum.GENERIC,
    took_action: false,
  };
  addComment = false;
  jobWithSameProducts: JobWithSameProduct | null = null;
  jobsWithSameProducts: JobWithSameProduct[] = [];

  throttledRefresh = debounce(this.refreshDocumentList, 400);
  @Watch('store.filters', {
    deep: true,
  })
  searchChange(value) {
    this.throttledRefresh();
  }

  created() {
    this.store.pagination.sortBy = 'createdAt';
    this.store.pagination.sortDir = true;
    this.refreshDocumentList();
  }
  // Computed
  get store(): ProductOrdersModule {
    return getModule(ProductOrdersModule, this.$store);
  }
  get pendingStore(): ProductOrdersPendingModule {
    return getModule(ProductOrdersPendingModule, this.$store);
  }
  get pendingProductItems() {
    return Object.values(this.pendingStore.items)
      .filter((item) => item.id === this.order.job.id)
      .map((item) => item.productsPendingOrder)
      .flat();
  }
  get columns() {
    return [
      {
        key: 'job_info',
        label: 'Job',
        sortable: true,
        sortKey: 'job.name',
      },
      {
        key: 'createdAt',
        label: 'Created',
        sortable: true,
        formatter: (value) => moment(value).locale(this.locale).format('L LT'),
      },
      {
        key: 'dateExpected',
        label: 'Date Expected',
        sortable: true,
      },
      {
        key: 'supplier.name',
        label: 'Supplier',
        sortable: true,
      },
      {
        key: 'job.jobWallboxInstallPriority.score',
        label: 'Car Delivery',
        thClass: 'text-nowrap',
        sortable: true,
      },
      {
        key: 'items',
        label: 'Products',
      },
      {
        key: 'trackingID',
        label: 'Tracking',
        sortable: true,
      },
      {
        key: '_actions',
        label: 'Actions',
        class: 'text-right text-nowrap',
      },
    ];
  }
  get carTypeExist() {
    return (item) => item.job?.jobWallboxInstallPriority?.carType?.length;
  }
  get carChargingTypeExist() {
    return (item) =>
      item.job?.jobWallboxInstallPriority?.chargingOpportunity?.length;
  }
  get scoreExist() {
    return (item) => item.job?.jobWallboxInstallPriority?.score;
  }
  get chargingType() {
    return (item) => {
      let humanizedType: TranslateResult = '';
      if (item?.job?.jobWallboxInstallPriority?.chargingOpportunity == 'Ja') {
        humanizedType = this.$t('yes');
      }
      if (item?.job?.jobWallboxInstallPriority?.chargingOpportunity == 'Nein') {
        humanizedType = this.$t('no');
      }
      return humanizedType;
    };
  }
  get carType() {
    return (item) => {
      let humanizedType: TranslateResult = '';
      if (
        item?.job?.jobWallboxInstallPriority?.carType ==
        'Voll elektrisches Auto'
      )
        humanizedType = this.$t('car_type.electric');
      if (item?.job?.jobWallboxInstallPriority?.carType == 'Hybrid-Auto')
        humanizedType = this.$t('car_type.hybrid');
      return humanizedType;
    };
  }

  get urgentReason() {
    return (item) => item?.job?.jobWallboxInstallPriority?.urgentReason;
  }

  get exportUrl() {
    const query = this.store.noPagingQueryString
      ? `${this.store.noPagingQueryString}&`
      : ``;
    return `/documents-api/export/xlsx/product-orders?${query}jwt=${this.accessToken}`;
  }

  get getProductOptions() {
    const selectedProducts = this.order.items.map((item) => item.product.id);
    const options = [
      ...this.pendingProductItems
        .filter((item) => item.supplierId === this.order.supplier.id)
        .map((item) => ({
          value: {
            product: { id: item.productId },
            productName: item.productName,
            quantity: Number(item.quantity),
          },
          text: item.productName,
        })),
      ...this.originalProductItems.map((item) => ({
        value: {
          product: { id: item.product.id },
          productName: item.productName,
          quantity: Number(item.quantity),
        },
        text: item.productName,
      })),
    ].filter((item) => !selectedProducts.includes(item.value.product.id));

    return options;
  }

  updateProductItem(event, index: number) {
    event.id = this.order.items[index].id;
    this.order.items.splice(index, 1, event);
  }

  buildSearch(filterTestJobs?: boolean): SCondition {
    const search: SCondition = {
      $and: [],
    };
    const textSearch: SCondition[] = [];
    if (this.store.filters.search) {
      search.$and!.push({
        $or: [
          {
            'job.name': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'job.eid': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'job.invoicingEid': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'job.customer.name': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'job.opportunity.name': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
        ],
      });
    }
    if (this.store.filters.opportunity.length > 0) {
      search.$and!.push({
        'job.opportunity.id': {
          [CondOperator.IN]: this.store.filters.opportunity.map(({ id }) => id),
        },
      });
    }
    if (this.store.filters.supplier.length > 0) {
      search.$and!.push({
        'supplier.id': {
          [CondOperator.IN]: this.store.filters.supplier.map(({ id }) => id),
        },
      });
    }
    if (this.store.filters.partner.length > 0) {
      search.$and!.push({
        'job.partner.id': {
          [CondOperator.IN]: this.store.filters.partner.map(({ id }) => id),
        },
      });
    }
    if (this.store.filters.product.length > 0) {
      search.$and!.push({
        'items.product.id': {
          [CondOperator.IN]: this.store.filters.product.map(({ id }) => id),
        },
      });
    }
    if (!filterTestJobs) {
      search.$and!.push({
        'job.testOrder': {
          [CondOperator.EQUALS]: false,
        },
      });
    }
    return search;
  }

  async refreshDocumentList(filterTestJobs?: boolean) {
    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
    };

    this.store.ITEMS_FETCH({
      query,
      search: this.buildSearch(filterTestJobs),
    });
  }

  async deleteItem({ id }) {
    if (
      await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this order?',
      )
    ) {
      this.store.ITEMS_DELETE_ITEM({ id });
    }
  }

  async editItem(item) {
    this.order = {
      ...JSON.parse(JSON.stringify(item)),
      eid: item.job.eid,
      opportunityId: item.job.opportunity.id,
      loading: true,
    };
    this.modal.show();
    const jps = await this.getJobProducts();

    const isOrderedByBrand = this.order.items.some((item) => {
      const product = jps.find((jp) => jp?.product?.id === item?.product?.id);

      return product?.supplier === SupplierTypesEnum.BRAND;
    });

    this.order = { ...this.order, isOrderedByBrand };

    this.originalProductItems = JSON.parse(JSON.stringify(item.items));
    this.newComment = {
      message: null,
      visible_for_roles: [],
      action_type: CommentActionTypeEnum.GENERIC,
      took_action: false,
    };
    this.addComment = false;
    this.jobWithSameProducts = {
      id: item.job.id,
      name: item.job.name,
    };
    const jobsWithSameProducts = await this.getPendingJobsWithSameProducts(
      item.id,
    );

    this.jobsWithSameProducts = [
      this.jobWithSameProducts,
      ...jobsWithSameProducts,
    ];
    await checkProductsRequiringSerialNumber(
      this.order,
      this.order.items.map(({ product }) => product),
    );
    this.order.items.forEach((item) => {
      item.product.serialNumber = jps.find(
        (jp) => jp.product.id == item.product.id,
      ).serialNumber;
    });
    this.order.loading = false;
  }

  async getJobProducts() {
    const query = RequestQueryBuilder.create()
      .select(['serialNumber', 'supplier'])
      .search({
        'job.id': { [CondOperator.IN]: [this.order.job.id] },
      })
      .query();
    return axios.get(`/data-api/job-product?${query}`).then(({ data }) => data);
  }

  async getPendingJobsWithSameProducts(productId: string) {
    return axios
      .get(
        `/documents-api/product-orders/${productId}/jobs-with-same-pending-products`,
      )
      .then(({ data }) => data);
  }

  async confirmEdit(bvModalEvent: BvModalEvent) {
    bvModalEvent.preventDefault();
    if (
      (this.order.carrier && !this.order.trackingID) ||
      (!this.order.carrier && this.order.trackingID)
    ) {
      this.$bvModal.msgBoxOk(
        'Please make sure both Carrier & Tracking ID fields are populated simultaneously',
        {
          title: 'Error',
          okVariant: 'danger',
          headerClass: 'text-danger',
          centered: true,
        },
      );
      return;
    }
    this.order.loading = true;
    const data = {
      ...this.order,
      eid: this.order.eid,
      dateExpected: this.order.dateExpected
        ? moment(this.order.dateExpected)
            .startOf('day')
            .add(12, 'hours')
            .locale(this.locale)
            .toISOString()
        : null,
      comment: this.addComment
        ? {
            message: this.newComment.message,
            visibleForRoles: this.newComment.visible_for_roles,
            actionType: this.newComment.action_type,
          }
        : undefined,
      serials: this.order.items.map((i) => ({
        productId: i.product.id,
        serialNumber: i.product.serialNumber,
      })),
    };

    if (this.jobWithSameProducts) {
      data.job = this.jobWithSameProducts;
    }

    try {
      await this.store.ITEMS_UPDATE_ITEM({
        data,
        noProgress: true,
      });
      this.jobWithSameProducts = null;
      this.jobsWithSameProducts = [];
      this.modal.hide();
    } catch (error: any) {
      console.log(error.response);
      this.order.error = error.response.data.message;
    }
    this.order.loading = false;
  }
}
