
import axios from 'axios';
import moment from 'moment';
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';

import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';
import CrudEditModal from '@gid/vue-common/components/crud-list/CrudEditModal.vue';
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange.vue';
import RichTextComments from '@gid/vue-common/components/inputs/RichTextComments.vue';
import ProductsList from './ProductsList.vue';
import UserInfo from './UserInfo.vue';
import ProductOrderFilters from './ProductOrderFilters.vue';
import ProductOrdersPendingModule from '../../store/product-orders-pending.module';
import {
  CondOperator,
  CreateQueryParams,
  RequestQueryBuilder,
  SCondition,
} from '@dataui/crud-request';
import { getModule } from 'vuex-module-decorators';
import { TranslateResult } from 'vue-i18n';
import { BIconFileEarmarkSpreadsheet, BvModalEvent } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import { CommentActionTypeEnum } from '@gid/models/dist/entities/comment';

export async function checkProductsRequiringSerialNumber(order, products) {
  const query = {
    filter: [
      {
        field: 'product.id',
        operator: CondOperator.IN,
        value: products.map(({ id }) => id),
      },
      {
        field: 'opportunity.id',
        operator: CondOperator.EQUALS,
        value: order.opportunityId,
      },
      {
        field: 'requireSerialNumber',
        operator: CondOperator.EQUALS,
        value: true,
      },
    ],
  };
  const qb = RequestQueryBuilder.create(query).query();
  const { data } = await axios.get(`/data-api/product-opportunity/?${qb}`);
  data.forEach((po) => {
    const item = products.find(({ id }) => id == po.product.id);
    if (item) {
      Vue.set(item, 'serialNumber', '');
      Vue.set(item, 'requireSerialNumber', true);
    }
  });
}

@Component({
  components: {
    ProductOrderFilters,
    CrudListTable,
    CrudListErrorMessage,
    CrudListPagination,
    CrudMultiSelect,
    CrudEditModal,
    DatePicker,
    ProductsList,
    UserInfo,
    DaysSinceLastChange,
    RichTextComments,
    BIconFileEarmarkSpreadsheet,
  },
  computed: {
    ...mapGetters({
      accessToken: 'access_token',
      authUser: 'user',
    }),
  },
})
export default class ProductOrdersPending extends Vue {
  @Ref('table') readonly table;

  accessToken!: String;
  authUser!: any;

  order: any = {
    carrier: null,
    shipTo: 'customer',
    trackingID: null,
    jobId: null,
    opportunityId: null,
    dateExpected: null,
    eid: null,
    supplierGroup: {
      id: null,
      name: null,
      products: [],
    },
    error: null,
    loading: false,
  };
  statuses: any[] = [];
  newComment = {
    message: null,
    visible_for_roles: [],
    action_type: CommentActionTypeEnum.GENERIC,
    took_action: false,
  };
  addComment = false;

  throttledRefresh = debounce(this.refreshDocumentList, 400);
  @Watch('store.filters', {
    deep: true,
  })
  searchChange() {
    this.throttledRefresh();
  }

  created() {
    this.refreshDocumentList();
    axios.get(`/api/admin/jobs/statuses`).then((response) => {
      this.statuses = response.data;
    });
  }

  // Computed
  get store(): ProductOrdersPendingModule {
    return getModule(ProductOrdersPendingModule, this.$store);
  }
  get columns() {
    return [
      {
        key: 'job_info',
        label: 'Job',
        sortable: true,
        sortKey: 'JobView.name',
      },
      {
        key: 'opportunity.name',
        sortable: true,
        label: 'Opportunity',
      },
      {
        key: 'customer',
      },
      {
        key: 'partner.name',
        sortable: true,
        label: 'Partner',
      },
      {
        key: 'jobWallboxInstallPriority.score',
        label: 'Car Delivery',
        thClass: 'text-nowrap',
        sortable: true,
      },
      {
        key: 'productsPendingOrder',
        label: 'Products by Supplier',
        sortable: true,
        sortKey: 'productsPendingOrder.supplierName',
        class: 'w-100',
      },
      {
        key: '_actions',
        label: '',
      },
    ];
  }

  get carTypeExist() {
    return (item) => item.jobWallboxInstallPriority?.carType?.length;
  }
  get carChargingTypeExist() {
    return (item) =>
      item.jobWallboxInstallPriority?.chargingOpportunity?.length;
  }
  get scoreExist() {
    return (item) => item.jobWallboxInstallPriority?.score;
  }
  get chargingType() {
    return (item) => {
      let humanizedType: TranslateResult = '';
      if (item?.jobWallboxInstallPriority?.chargingOpportunity == 'Ja') {
        humanizedType = this.$t('yes');
      }
      if (item?.jobWallboxInstallPriority?.chargingOpportunity == 'Nein') {
        humanizedType = this.$t('no');
      }
      return humanizedType;
    };
  }

  get carType() {
    return (item) => {
      let humanizedType: TranslateResult = '';
      if (item?.jobWallboxInstallPriority?.carType == 'Voll elektrisches Auto')
        humanizedType = this.$t('car_type.electric');
      if (item?.jobWallboxInstallPriority?.carType == 'Hybrid-Auto')
        humanizedType = this.$t('car_type.hybrid');
      return humanizedType;
    };
  }

  get urgentReason() {
    return (item) => item?.jobWallboxInstallPriority?.urgentReason;
  }

  get exportUrl() {
    const query = this.store.noPagingQueryString
      ? `${this.store.noPagingQueryString}&`
      : ``;
    return `/documents-api/export/xlsx/product-orders-pending?${query}jwt=${this.accessToken}`;
  }

  get disableConfirmOrderBtn() {
    return (
      this.order.loading ||
      this.productsToOrder.length == 0 ||
      (this.newComment.took_action &&
        this.newComment.action_type == CommentActionTypeEnum.GENERIC)
    );
  }

  buildSearch(filterTestJobs?: boolean): SCondition {
    const search: SCondition = {
      $and: [],
    };
    if (this.store.filters.search) {
      search.$and!.push({
        $or: [
          {
            'JobView.name': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'customer.name': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'partner.name': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'JobView.invoicingEid': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'JobView.eid': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
          {
            'opportunity.name': {
              [CondOperator.CONTAINS_LOW]: this.store.filters.search,
            },
          },
        ],
      });
    }
    if (this.store.filters.opportunity.length > 0) {
      search.$and!.push({
        'opportunity.id': {
          [CondOperator.IN]: this.store.filters.opportunity.map(({ id }) => id),
        },
      });
    }
    if (this.store.filters.supplier.length > 0) {
      search.$and!.push({
        'productsPendingOrder.supplierId': {
          [CondOperator.IN]: this.store.filters.supplier.map(({ id }) => id),
        },
      });
    }
    if (this.store.filters.partner.length > 0) {
      search.$and!.push({
        'productsPendingOrder.partner.id': {
          [CondOperator.IN]: this.store.filters.partner.map(({ id }) => id),
        },
      });
    }
    if (this.store.filters.product.length > 0) {
      search.$and!.push({
        'productsPendingOrder.productId': {
          [CondOperator.IN]: this.store.filters.product.map(({ id }) => id),
        },
      });
    }

    if (!filterTestJobs) {
      search.$and!.push({
        'JobView.testOrder': {
          [CondOperator.EQUALS]: false,
        },
      });
    }
    return search;
  }

  async refreshDocumentList(filterTestJobs?: boolean) {
    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
    };

    this.store.ITEMS_FETCH({
      query,
      search: this.buildSearch(filterTestJobs),
    });
  }

  async onOrder(supplierGroup, item) {
    const modal: any = this.$refs.orderModal;
    this.order.jobId = item.job.id;
    this.order.opportunityId = item.job.opportunityId;
    this.order.eid = item.job.eid;
    this.order.supplierGroup = supplierGroup;
    this.order.supplierGroup.products.forEach((product: any) => {
      Vue.set(product, 'maxQuantity', product.quantity);
    });
    modal.show();
    this.order.loading = true;
    await checkProductsRequiringSerialNumber(
      this.order,
      this.order.supplierGroup.products,
    );
    const jps = await this.getJobProducts(item.job.id);
    this.order.supplierGroup.products.forEach((product) => {
      product.serialNumber = jps.find(
        (jp) => jp.product.id == product.id,
      ).serialNumber;
    });
    this.order.loading = false;
  }

  async getJobProducts(jobId) {
    const query = RequestQueryBuilder.create()
      .select(['serialNumber'])
      .search({
        'job.id': { [CondOperator.EQUALS]: jobId },
      })
      .query();
    return axios.get(`/data-api/job-product?${query}`).then(({ data }) => data);
  }

  get productsToOrder() {
    return this.order.supplierGroup.products
      .filter(({ quantity }) => quantity > 0)
      .map((product: any) => ({
        productName: product.name,
        quantity: product.quantity,
        product: { id: product.id },
      }));
  }

  async confirmOrder(bvModalEvent: BvModalEvent) {
    const modal: any = this.$refs.orderModal;
    bvModalEvent.preventDefault();
    if (
      (this.order.carrier && !this.order.trackingID) ||
      (!this.order.carrier && this.order.trackingID)
    ) {
      this.$bvModal.msgBoxOk(
        'Please make sure both Carrier & Tracking ID fields are populated simultaneously to place the product order',
        {
          title: 'Error',
          okVariant: 'danger',
          headerClass: 'text-danger',
          centered: true,
        },
      );
      return;
    }

    this.order.loading = true;
    try {
      await axios.post(`/documents-api/product-orders`, {
        job: { id: this.order.jobId },
        shipTo: this.order.shipTo,
        supplier: { id: this.order.supplierGroup.id },
        carrier: this.order.carrier ? { id: this.order.carrier.id } : undefined,
        trackingID: this.order.trackingID,
        eid: this.order.eid,
        dateExpected: this.order.dateExpected
          ? moment(this.order.dateExpected)
              .startOf('day')
              .add(12, 'hours')
              .toISOString()
          : null,
        items: this.productsToOrder,
        comment: this.addComment
          ? {
              message: this.newComment.message,
              visibleForRoles: this.newComment.visible_for_roles,
              actionType: this.newComment.action_type,
            }
          : undefined,
        createdByContact: { id: this.authUser.contact.sfid },
        serials: this.order.supplierGroup.products
          .filter(({ serialNumber }) => serialNumber)
          .map((product) => {
            return {
              productId: product.id,
              serialNumber: product.serialNumber,
            };
          }),
      });
      modal.hide();
      this.order.carrier = null;
      this.order.trackingID = null;
      this.refreshDocumentList();
    } catch (error: any) {
      console.error(error);
      this.order.error = error.response?.data?.message;
    }
    this.order.loading = false;
  }

  statusLocalized(status) {
    const localizedStatus = this.statuses?.find(
      ({ job_status }) => job_status == status,
    );
    return localizedStatus
      ? localizedStatus.gid_name[this.$store.getters.locale]
      : status;
  }
}
